import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import TextImagesDONT from '@images/a11y/1.1-Text-Images-DONT.png';
import LinkTextDo from '@images/a11y/Native-link-text-1.4-Do.png';
import LinkTextDont from '@images/a11y/Native-link-text-1.4-Dont.png';
import DecorativeImageDo from '@images/a11y/Native-decorative-image-1.5-Do.png';
import DecorativeImageDont from '@images/a11y/Native-decorative-image-1.5-Dont.png';
import ColourAloneDO2 from '@images/a11y/2.4-Colour-Alone-DO_2.png';
import ColourAloneDONT2 from '@images/a11y/2.4-Colour-Alone-DONT_2.png';
import TextAlternativesDO from '@images/a11y/1.2-Text-alternatives-DO.png';
import TextAlternativesDONT from '@images/a11y/1.2-Text-alternatives-DONT.png';
import TextAlternativesDO2 from '@images/a11y/1.2-Text-alternatives-DO_2.png';
import TextAlternativesDONT2 from '@images/a11y/1.2-Text-alternatives-DONT_2.png';
import TextAlternativesDO4 from '@images/a11y/1.2-Text-alternatives-DO_4.png';
import TextAlternativesDONT4 from '@images/a11y/1.2-Text-alternatives-DONT_4.png';
import TextAlternativesDO5 from '@images/a11y/Text-alternatives-DO_5.png';
import TextAlternativesDO6 from '@images/a11y/Text-alternatives-DO_6.png';
import { Accordion, AccordionItem } from '@jsluna/accordion';
import { default as CodeSnippet } from '@components/accessibility-sections/CodeSnippet';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Section = makeShortcode("Section");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const Display2 = makeShortcode("Display2");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <p>{`The following native standards are based on the `}<a href="https://www.w3.org/TR/WCAG22/" target="_blank">{`Web Content Accessibility Guidelines (WCAG 2.2), `}</a>{`accessibility best practices and customer and colleague feedback.`}</p>
    <p>{`For support making products accessible or to provide some feedback, `}<a parentName="p" {...{
        "href": "#get-in-touch"
      }}>{`get in touch with us`}</a>{`.`}</p>
    <hr></hr>
    <Section mdxType="Section">
  <Accordion titleElement="h3" mdxType="Accordion">
    <AccordionItem title="1.1 Use simple and concise language" mdxType="AccordionItem">
      <p>
        The longer or more complicated words we use, the more people will
        struggle to understand and skip over our words. So we need to choose
        short, familiar words throughout our emails.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <div>Turn off delivery tracking</div>
          <div>Set up your account</div>
          <div>Edit your payment details</div>
        </Do>
        <Dont mdxType="Dont">
          <div>Deactivate delivery tracking</div>
          <div>Configure your account information</div>
          <div>Modify your payment details</div>
        </Dont>
      </Guideline>
      <p>
        People don’t want to read lots of words in emails, so get the message
        across using as few words as possible without losing the meaning.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            Since we’re all working from home, we might do our next meeting
            online.
          </p>
        </Do>
        <Dont mdxType="Dont">
          <p>
            In light of the fact we are all working from home, there is a
            possibility that we have to do the next meeting online.
          </p>
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.2 Left align blocks of text" mdxType="AccordionItem">
      <p>
        Left-aligned text is widely regarded as easier and more efficient to
        read than centre or justified-aligned text. A block is any piece of text
        that isn’t a heading.
      </p>
      <p>
        The impact can be much greater on people with certain cognitive and
        vision impairment and disabilities such as dyslexia and low vision, so
        we need to make sure all blocks of texts in our emails are
        left-aligned.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <div className="ln-u-border ln-u-soft">
            <p>This section of text is left aligned.</p>
            <p>
              The start of each line and the spacing between words is
              predictable and consistent which makes scanning and reading
              easier.
            </p>
          </div>
        </Do>
        <Dont mdxType="Dont">
          <div className="ln-u-border ln-u-soft">
            <p className="ln-u-text-align-center">
              This section of text is centre aligned.
            </p>
            <p className="ln-u-text-align-center">
              The start of each line is unpredictable which makes scanning and
              reading harder and can be very disorientating for some users.
            </p>
          </div>
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.3 Structure content so it’s easy to navigate" mdxType="AccordionItem">
      <p>
        Split up blocks of text with frequent headings so people can easily find
        the information they’re looking for in your emails.
      </p>
      <p>
        It’s best to use a heading every time you start talking about something
        new. Design emails so you can limit the text under each heading to a few
        sentences.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            <strong>Log in to your account</strong>
          </p>
          <p>
            Use your email and password you set when you registered to log in to
            your account.
          </p>
          <p>
            <strong>Create an account</strong>
          </p>
          <p>
            On the website, follow these steps:
            <ul>
              <li>Go to ‘Account’ to register</li>
              <li>Add in your email and personal details</li>
              <li>Create a secure password</li>
            </ul>
          </p>
          <p>
            <strong>Update your account</strong>
          </p>{' '}
          <p>
            Log in to your account via the website and from there you’ll be able
            to view your orders, update your details or add payment methods.
          </p>
        </Do>
        <Dont mdxType="Dont">
          <p>
            <strong>Account information</strong>
          </p>{' '}
          <p>
            To log in to your account, use your email and password you set when
            you registered. If you’ve forgotten your password you can reset your
            password using your email.
          </p>
          <p>
            If you need to create an account, go to ‘Account’ on the website,
            then add in your email and create a secure password.
          </p>
          <p>
            To make changes to your account, you’ll need to log in via the
            website. From here you’ll be able to view your orders, update your
            details or add payment methods.
          </p>
        </Dont>
      </Guideline>
      <p>
        For headings, use the H1 to H6 heading structure to help screen reader
        users navigate and identify sections of content. This means making sure
        that you use the heading options in your email client and all headings
        are coded correctly in templates.
      </p>
      <p>You should also create lists in this way using the number or bullet point function provided in the email client. If you’re creating an HTML email template, make sure list elements are correctly coded using ul, ol, and li elements.</p>
      <p>
        You can read more about{' '}
        <a href="https://www.w3.org/WAI/tutorials/page-structure/headings/" target="_blank">
          HTML headings
        </a>{' '}
        and{' '}
        <a href="https://www.w3.org/WAI/tutorials/page-structure/content/#lists" target="_blank">
          lists{' '}
        </a>
        on the W3C WAI Tutorials website.
      </p>
    </AccordionItem>
    <AccordionItem title="1.4 Use descriptive link text" mdxType="AccordionItem">
      <p>
        Use descriptive phrases for your link text instead of generic phrases
        like ‘click here’ or ‘find out more’.
      </p>
      <p>
        Make sure you also underline links when they are in a sentence or a
        block of text. This helps make the link more distinguishable, especially
        for people with certain types of colour blindness.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            Download our new <a href="#">delivery tracking app</a>
          </p>
        </Do>
        <Dont mdxType="Dont">
          <p>
            <a href="#">Click here</a> to download our new delivery tracking app
          </p>
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.5 Don’t use images of text" mdxType="AccordionItem">
      <p>
        An image is a collection of pixels that contain no information, whereas
        text can be highlighted, copied and read by screen readers, search
        engines and other assistive technologies.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <Display2 element="h3" mdxType="Display2">This is real text</Display2>
          <p>It can be highlighted, copied and read out by a screen reader</p>
        </Do>
        <Dont mdxType="Dont">
          <img src={TextImagesDONT} alt="This is an image of text, it can't be highlightedc copied or read out by a screen reader. It also needs a text alternative" />
        </Dont>
      </Guideline>
      <p>
        If you have to use an image of text in an email, make sure you add alt
        text that includes the text delivered in the image. Learn how to{' '}
        <a href="https://support.microsoft.com/en-us/office/video-improve-image-accessibility-in-email-e93969e3-21c1-47ad-a4c5-0e5fb868a7f8" target="_blank">
          add alt text to an image in Outlook
        </a>
        .
      </p>
            <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            If you have to use images of text then make sure the information in the image is included in the alternative text.
          </p>
          <img src={TextAlternativesDO5} alt="Sainsbury's logo" />
          <p>
              <code>alt="Keep collecting to unwrap more with Nectar"</code>
          </p>
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without a text alternative the image would be ignored by a screen
            reader.
          </p>
          <img src={TextAlternativesDO5} alt="Sainsbury's logo" alt="" />
                    <p>
            This will be ignored <br />
              <code>alt=""</code>
              </p>
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.6 Provide text alternatives for images" mdxType="AccordionItem">
      <p>
        Most images need a text alternative to convey the information in the
        image for screen reader users.
      </p>
      <p>
        Learn how to{' '}
        <a href="https://support.microsoft.com/en-us/office/video-improve-image-accessibility-in-email-e93969e3-21c1-47ad-a4c5-0e5fb868a7f8" target="_blank">
          add alt text to an image in Outlook. 
        </a> {' '}
        If you're not sure whether an image needs a text alternative, you can{' '}
        <a href="https://www.w3.org/WAI/tutorials/images/decision-tree/" target="_blank">
          refer to the W3C alt decision tree
        </a>{' '}
        for help.
      </p>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            This is a logo, it could be used in multiple ways including as a
            link to the homepage.
          </p>
          <img src={TextAlternativesDO6} alt="Sainsbury's logo" />
          <p>
            Just a logo <br />
              <code>alt="Sainbury's logo"</code>
              </p>
              <p>
            Link to the homepage
            <br /> <code>alt="Sainbury's homepage"</code>
          </p>
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without a text alternative the image would be ignored by a screen
            reader or it's file name read out instead.
          </p>
          <img src={TextAlternativesDO6} alt="Sainsbury's logo" alt="" />
                    <p>
            This will be ignored <br />
              <code>alt=""</code>
              </p>
        </Dont>
      </Guideline>
      <Guideline mdxType="Guideline">
        <Do mdxType="Do">
          <p>
            The delivery van is decorative, it's not needed for users to
            understand the adjacent information so it can be hidden from screen
            reader users
          </p>
          <img src={TextAlternativesDO4} alt="A delivery van image with the adjacent text, Save up to £168 per year, Pay no delivery charges 7 days a week" />
        </Do>
        <Dont mdxType="Dont">
          <p>
            Without a text alternative the image would be ignored by a screen
            reader or it's file name read out instead.
          </p>
          <img src={TextAlternativesDONT4} alt="A design of a retail product card with no text alternative annotations" />
        </Dont>
      </Guideline>
    </AccordionItem>
    <AccordionItem title="1.7 Don’t put animations on loop" mdxType="AccordionItem">
      <p>
        Animation can be intrusive and frustrating for people who find movement
        distracting or who need more time to read, so avoid animations where
        possible, especially when they feature text.
      </p>
      <p>
        If you do use animations, they should be under 3 seconds and they
        shouldn’t loop.
      </p>
    </AccordionItem>
  </Accordion>
    </Section>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions about web accessibility or help you with
  your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      